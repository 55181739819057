$(function () {
    document.body.addEventListener('ajax:success', function(event) {
        if(typeof(global_precert_html) == 'undefined')
            return;
        if (document.getElementById('precert-epa-form')) {
            return
        }
        //var precertFormDiv = document.createElement('div');
        //precertFormDiv.setAttribute('id', 'precert-epa-form');
        //document.getElementById('step-3').appendChild(precertFormDiv);
        //document.getElementById('precert-epa-form').innerHTML = global_precert_epa_html;
    });

});
handleFormSubmission_epa = function(event) {
   
    var actionText = (event.target.innerText && event.target.innerText.toLowerCase()) || 'proceed'

    var confirmationMessage = `Are you sure you would like to ${actionText}? This action can not be undone.`

    var confirmed = event.target.value.split('-')[0] === 'save' || confirm(confirmationMessage)

    if(confirmed) {
        //toggleSubmissionButtonSpinners_epa()
        if(event.target.value.split('-')[0] === 'save'){
            $('form input:visible').removeAttr('required');
        }
        return true
    } else {
        event.preventDefault()
        return false
    }
}

handleFormCancel_epa = function(event) {

    var actionText = (event.target.innerText && event.target.innerText.toLowerCase()) || 'proceed'

    var confirmationMessage = `Are you sure you would like to ${actionText}? This action can not be undone.`

    var confirmed = event.target.value === 'save' || confirm(confirmationMessage)
    
    if(confirmed) {
        $('#precert-epa-form input:visible').removeAttr('required');
        return true
    }
}



handleInputSelect_epa = function(target) {
    handlePostProssing(target);
    var precertFormsFromServer = JSON.parse(global_precert_epa_form)
    var formId = $(target).closest('form').attr('id')
    var id_arr = formId.split('-')
    id_arr.pop();
    formId = id_arr.join('-')
    var precertFormFromServer = precertFormsFromServer.find(function(form) {
        return form.id == formId
    }) || {}

    var precertFormFieldsFromServer = precertFormFromServer.fields || []

    var fieldName = target.name.split('precert[').join('').split(']').join('')

    var targetField = precertFormFieldsFromServer.find(function(field) {
        return field.name === fieldName
    })
    if (!targetField) { return }

    var children = precertFormFieldsFromServer.reduce(function(acc, curr) {
        if(curr.parent_id && curr.parent_id.includes(targetField.id)) {
            acc.push(curr)
        }

        return acc
    }, [])
    
    var targetValue = extractTargetValue_epa(target)
    var targetAnswers = extractTargetAnswers_epa(targetField)

    children.forEach(function(child) {
        if(child.name == null){
            return;
        }
        var childRow = document.getElementById(`${child.name}_row`)
        //!childRow && (childRow = document.getElementById(`label_${child.id}_row`))
        var childElement = $(childRow).find(`:input[name="precert[${escapeSelector(child.name)}]"]`)
        !childElement.length && (childElement = $(childRow).find(`label[parent_id="${targetField.id}"]`))
        var renderCondition = child.render_condition[targetField.id]
        console.log(target)
        handleRenderCondition_epa(child, childRow, renderCondition, targetValue, targetAnswers, childElement, targetField, target)

        if(childRow.hidden === true){
            resetChild(child, childElement)
            var childs = precertFormFieldsFromServer.reduce(function(acc, curr) {
                if(curr.parent_id && curr.parent_id.includes(child.id)) {
                    acc.push(curr)
                }
                return acc
            }, [])
            childs.forEach(function(ch){
                ch_row = document.getElementById(`${ch.name}_row`)
                ch_row.hidden = true;
                var ch_el = $(ch_row).find(`:input[name="precert[${ch.name}]"]`)
                resetChild(ch, ch_el)
            })
        }

    })
    }

function resetChild(child, childElement){
    childElement.removeAttr('required');
    if(child.category == 'Date'){
            childElement.val('').attr('type', 'text').attr('type', 'date');
        } else if(child.category == 'yesno'){
            childElement.prop('checked', false)  
        } else if(child.category == 'Radio'){
            childElement.prop('checked', false)
        } else {
            childElement.val('')
        }
}

handlePostProssing = function(target){
  if($(target).attr('type') == 'checkbox'){
    $(target).parents(".checkboxes").find("input[type='checkbox']").removeAttr('required');
    handleValidation(target,false);
  }
}

handleRenderCondition_epa = function(child, childRow, renderCondition, targetValue, targetAnswers, childElement, targetField, target) {
    console.log("Entered handleRenderCondition_epa", { child, childRow, renderCondition, targetValue, targetAnswers, childElement, targetField, target });

    if (childRow && renderCondition === 'false' && shouldRenderOnFalse_epa(targetValue)) {
        console.log("Condition: renderCondition === 'false' && shouldRenderOnFalse_epa", { targetValue });
        toggleRow(childRow, targetField, target);
        handleValidation(childElement);

    } else if (childRow && renderCondition[0] === true && shouldRenderOnTrue_epa(targetValue)) {
        console.log("Condition: renderCondition[0] === true && shouldRenderOnTrue_epa", { targetValue });
        toggleRow(childRow, targetField, target);
        handleValidation(childElement);

    } else if (childRow && renderCondition && shouldRenderFromAnswers_epa({ value: targetValue, answers: targetAnswers, condition: renderCondition, targetField: targetField })) {
        console.log("Condition: shouldRenderFromAnswers_epa", { targetValue, targetAnswers, renderCondition });
        $(childRow).find('input').removeAttr('disabled');
        toggleRow(childRow, targetField, target);
        handleValidation(childElement);

    } else if (childRow) {
        console.log("Condition: default else if childRow is present");
        toggleRow(childRow, targetField, target, true);
        resetChild(child, childElement);
    }
}


toggleRow = function(childRow, targetField, target, hide) {
    console.log("Entered toggleRow", { childRow, targetField, target, hide });

    var sel = escapeSelector(targetField.name);
    console.log("Selector after escaping", sel);

    $(target).closest('.form-group').after(childRow);
    console.log("Placed childRow after the closest '.form-group' to target");

    childRow.hidden = hide ? true : false;
    console.log("Set childRow hidden status", { hidden: childRow.hidden });
}

escapeSelector = function(sel) { // $.escapeSelector()
    var rcssescape = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g;
    var fcssescape = function(ch, asCodePoint) {
        if (asCodePoint) {
            // U+0000 NULL becomes U+FFFD REPLACEMENT CHARACTER
            if (ch === "\0") {
                return "\uFFFD";
            }
            // Control characters and (dependent upon position) numbers get escaped as code points
            return ch.slice(0, -1) + "\\" + ch.charCodeAt(ch.length - 1).toString(16) + " ";
        }
        // Other potentially-special ASCII characters get backslash-escaped
        return "\\" + ch;
    };

    return (sel + '').replace(rcssescape, fcssescape);
};


handleValidation = function(childElement,hide){
    console.log(childElement);
    console.log("dataaaaaaa",$(childElement).data() && $(childElement).data().required);
    hide ? $(childElement).attr('disabled', 'disabled') : $(childElement).removeAttr('disabled')
     if(!hide && $(childElement).data() && $(childElement).data().required){
    $(childElement).attr('required','required');
  } else  {
    $(childElement).removeAttr('required');
  }

  $(childElement.context).find("input[type='checkbox']").each(function(index,elem){
    console.log("going deep ",elem);
    handleValidation(elem,hide);
  });
}

shouldRenderOnFalse_epa = function(value) {
    return value && (value === '' || value == false || value === 'no')
}

shouldRenderOnTrue_epa = function(value) {
    return value && (value !== '' && value != false && value !== 'no')
}

shouldRenderFromAnswers_epa = function(props={}) {
    console.log("Entered shouldRenderFromAnswers_epa", props);

    var value = props.value;
    var answers = props.answers;
    var condition = props.condition;

    console.log("Processing properties", { value: value, answers: answers, condition: condition });

    if (props.targetField.category == 'numeric') {
        console.log("Handling numeric field condition");
        return renderNumericConditional(props);
    }

    var ans_index = answers.indexOf(value.toLowerCase()) + 1;
    console.log("Index of value in answers", ans_index);

    ans_index = ans_index.toString(10);
    console.log("Index as string", ans_index);

    if (condition.includes(ans_index) || condition.includes(value)) {
        console.log("Condition met", { includesIndex: condition.includes(ans_index), includesValue: condition.includes(value) });
        return true;
    } else {
        console.log("Condition not met");
        return false;
    }
}


renderNumericConditional = function(props = {}){
    if(props.condition.comparison_type == "single_comparison"){
        var op = mapOperator(props.condition.comparison_operator)
        return eval(props.value+op+props.condition.comparison_value)
    } else {
        var lb_op = mapOperator(props.condition.lb_comparison_operator);
        var ub_op = mapOperator(props.condition.ub_comparison_operator);
        var first_operation = eval(props.value+lb_op+props.condition.lb_comparison_value);
        var second_operation = eval(props.value+ub_op+props.condition.ub_comparison_value);

        return (first_operation && second_operation);
        // return eval(props.condition.lb_comparison_value+lb_op+props.value+ub_op+props.condition.ub_comparison_value)
    }
}

mapOperator = function(op_str){
    switch(op_str){
            case 'LE':
                op = '<='
                break;
            case 'LT':
                op = '<'
                break;
            case 'GT':
                op = '>'
                break;
            case 'EQ':
                op = '=='
                break;
            case 'GE':
                op = '>='
                break;
            case 'NE':
                op = '!='
                break;
        }
        return op;
}

extractTargetValue_epa  = function(target) {
    if(target.type === 'checkbox') {     
        var target_class = target.name.split('precert[').join('').split(']').join('')
        var selected_vals = []  
        $.each($(`[class*="${target_class}"]:checkbox:checked`), function(i, e){selected_vals.push(e.value.toLowerCase())})
        $(`input[class*="${target_class}"]`).val(selected_vals);
        return selected_vals;
    } else if (target.value && typeof target.value === 'string') {
        return target.value
    } else {
        return target.value
    }
}

extractTargetAnswers_epa = function(target={}) {
    var answers = target.answers || ''

    if(answers && Array.isArray(answers)) {
        return answers.map( i => `${i}`.toLowerCase() )
    } else {
        return answers
    }
}

toggleSubmissionButtonSpinners_epa = function(hideButtons = true) {
    var showSpinner = !hideButtons

    var formButtons = document.getElementsByClassName('precert-form-buttons')
    var loadingSpinners = document.getElementsByClassName('precert-form-loading-container')

    $(formButtons).each(function(index) {
        formButtons[index].hidden = hideButtons
    })

    $(loadingSpinners).each(function(index) {
        loadingSpinners[index].hidden = showSpinner
    })

}

updatePrecertFormSelection_epa  = function(element) {
    var formId = element.options[element.options.selectedIndex].value
    $('.precert_form_field_option').each(function() {
        $(this).addClass('d-none')
    })

    var saveAndCompleteButton = $('#save_and_complete_button')

    if(formId) {
        $(`.precert_form_id-${formId}`).removeClass('d-none')
        $(saveAndCompleteButton).prop('disabled', false)
        $(saveAndCompleteButton).prop('title', 'You can now submit this form to the insurance company!')
    } else {
        $(saveAndCompleteButton).prop('disabled', 'disabled')
        $(saveAndCompleteButton).prop('title', 'A form must be selected (in the above drop-down) to be submitted to the insurance company.')
    }
}

epaDocumentUpload = function(e, path) {
    var formData = new FormData();
    // Attach file
    formData.append('file', $('input[type=file]')[0].files[0]);
    $.ajax({
        url: path,
        enctype: 'multipart/form-data',
        data: formData,
        type: 'POST',
        format: 'js',
        // contentType: "application/json",
        contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
        processData: false, // NEEDED, DON'T OMIT THIS
        cache: false,
        timeout: 600000
    });
    e.preventDefault()
}

$('#precert-epa-form')
    .on('ajax:error', function(event) {
        toggleSubmissionButtonSpinners_epa(false)

        toastr['error']('Internal server error. Please contact IT.')
    })

document.addEventListener("turbolinks:load", function() {
      var precertFormSelect = $('select[name*="[form_id]"]')[0]

    if(precertFormSelect) {
        updatePrecertFormSelection_epa(precertFormSelect)
    }
    $('#precert-epa-form input:visible:checked').trigger('click');
});
